
































import {Component, Mixins} from 'vue-property-decorator';
import NodesTable from '@/components/node/nodes-table.vue';
import {BBadge, BIconSearch, BTable, BIconExclamationTriangle, BListGroup, BListGroupItem} from 'bootstrap-vue';
import {StoreMixin} from '@/mixins/StoreMixin';
//import AsyncComputed from 'vue-async-computed-decorator';
import {IsLoadingMixin} from '@/mixins/IsLoadingMixin';
import {NodeSnapShot} from '@stellarbeat/js-stellar-domain/lib/node-snap-shot';

@Component({
    components: {
        NodesTable,
        BIconSearch: BIconSearch,
        BBadge: BBadge,
        BTable,
        BIconExclamationTriangle,
        BListGroup,
        BListGroupItem
    }
})
export default class NetworkValidatorUpdates extends Mixins(StoreMixin, IsLoadingMixin) {
    failed: boolean = false;
    snapshots: NodeSnapShot[] = [];

    async getSnapshots() {
        let snapshots: NodeSnapShot[] = [];
        try {
            snapshots = await this.store.fetchNodeSnapshots();
        } catch (e) {
            console.log(e);
            this.failed = true;
        }
        this.isLoading = false;

        return snapshots;
    }

    async mounted(){
        this.snapshots = await this.getSnapshots();
    }
}
