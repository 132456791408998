






















































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import NodesCountryDistribution from '@/components/network/cards/nodes-country-distribution.vue';
import NodesVersions from '@/components/network/cards/nodes-versions.vue';
import ValidatorsServerLoad from '@/components/network/cards/validator-load.vue';
import {Network} from '@stellarbeat/js-stellar-domain';
import NetworkStatistics from '@/components/network/cards/network-statistics/network-statistics.vue';
import NetworkNodes from '@/components/network/cards/network-nodes.vue';
import NetworkOrganizations from '@/components/network/cards/network-organizations.vue';
import NetworkAnalysis from '@/components/network/cards/network-risk-analysis-charts/network-analysis.vue';
import {BCard, BListGroup, BListGroupItem, BBadge,BAlert} from 'bootstrap-vue';
import LivenessInfo from '@/components/network/cards/network-risk-analysis-charts/liveness-info.vue';
import SafetyInfo from '@/components/network/cards/network-risk-analysis-charts/safety-info.vue';
import NetworkValidatorUpdates from '@/components/network/cards/network-validator-updates.vue';
import NetworkOrganizationUpdates from '@/components/network/cards/network-organization-updates.vue';
import Store from '@/store/Store';
import LazyHydrate from 'vue-lazy-hydration';
import NetworkHorizon from '@/components/network/cards/network-horizon.vue';

@Component({
    components: {
        NetworkHorizon,
        NetworkOrganizationUpdates,
        LazyHydrate,
        NetworkValidatorUpdates,
        SafetyInfo,
        LivenessInfo,
        NetworkAnalysis,
        NetworkOrganizations,
        NetworkNodes,
        NetworkStatistics,
        ValidatorsServerLoad,
        NodesVersions,
        NodesCountryDistribution,
        BCard,
        BListGroup,
        BListGroupItem,
        BBadge,
        BAlert
    }
})

export default class NetworkDashboard extends Vue {
    get network(): Network {
        return this.$root.$data.store.network;
    }

    get store(): Store {
        return this.$root.$data.store;
    }
}
