











































































import {ChartData, ChartDataSets, ChartLegendLabelItem, ChartTooltipItem} from 'chart.js';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {BTooltip, BIconInfoCircle, BButton, BButtonGroup, BIconExclamationTriangle, BIconExclamationCircle, VBTooltip, BModal, VBModal} from 'bootstrap-vue';
import DateNavigator from '@/components/date-navigator.vue';
import moment from 'moment';
import NetworkStatisticsAggregation from '@stellarbeat/js-stellar-domain/lib/network-statistics-aggregation';
import AggregationLineChart
    from '@/components/network/cards/network-risk-analysis-charts/aggregation-line-chart.vue';
import StatisticsDateTimeNavigator
    from '@/components/network/cards/network-risk-analysis-charts/StatisticsDateTimeNavigator';
import {IsLoadingMixin} from '@/mixins/IsLoadingMixin';
import {StoreMixin} from '@/mixins/StoreMixin';
import NetworkStatistics from '@stellarbeat/js-stellar-domain/lib/network-statistics';

@Component({
    components: {AggregationLineChart, DateNavigator, BTooltip, BIconInfoCircle, BButton, BButtonGroup, BIconExclamationCircle, BModal, BIconExclamationTriangle},
    directives: {'b-tooltip': VBTooltip, 'b-modal': VBModal}
})
export default class NetworkAnalysis extends Mixins(IsLoadingMixin, StoreMixin) {
    @Prop()
    analysisType!:string; //safety of liveness

    @Prop({default: '1Y'})
    defaultBucketSize!:string;

    protected selectedDate!: Date;
    protected yearStatistics: NetworkStatisticsAggregation[] = [];
    protected days30Statistics: NetworkStatisticsAggregation[] = [];
    protected hour24Statistics: NetworkStatistics[] = [];
    protected initialDataLoaded: boolean = false;
    protected statisticsDateTimeNavigator!: StatisticsDateTimeNavigator;
    protected bucketSize: string = this.defaultBucketSize;
    protected failed: boolean = false;
    animated: boolean = false;
    protected showModal:boolean = false;

    get setType() {
        return this.analysisType === 'safety' ? 'splitting' : 'blocking';
    }

    get canBeFiltered() {
        return this.setType === 'blocking';
    }

    async updateSelectedDate(newDate: Date) {
        this.selectedDate = newDate;
        if (this.bucketSize === '1Y')
            await this.updateYearChart();
        if (this.bucketSize === '30D')
            await this.updateDays30Chart();
        if (this.bucketSize === '24H')
            await this.updateHours24Chart();
    }

    async select1YView(time?: Date) {
        if (time instanceof Date)
            this.selectedDate = moment(time).startOf('hour').toDate();

        await this.updateYearChart();

        this.bucketSize = '1Y';
    }

    aggregatedChartLabelFilter(legendItem: ChartLegendLabelItem, data: ChartData): any {
        if (legendItem.datasetIndex === 0 || legendItem.datasetIndex === 3)
            return true; //don't show labels for the min max as they are auxiliary lines
    }

    getAggregatedDataSets(statisticsAggregation: NetworkStatisticsAggregation[]) {
        return [
            {
                label: 'Organizations',
                borderColor: '#2396cf', // primary blue
                backgroundColor: '#2396cf', // primary blue
                borderWidth: 2,
                steppedLine: true,
                fill: false,
                type: 'line',
                radius: 2,
                data: statisticsAggregation.map(stat => {
                    if(stat.crawlCount === 0)
                        return {};
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "SetOrgs" + (this.canBeFiltered ? 'Filtered' : '') + "Average"]
                    };
                }),
            },
            {
                label: 'min(|organizations|)',
                borderWidth: 4,
                borderColor: 'transparent', // primary blue
                backgroundColor: 'rgba(25,151,198,0.1)',
                steppedLine: true,
                fill: '0', //relative to dataset with index zero
                type: 'line',
                radius: 0, hitRadius: 0, hoverRadius: 0,
                data: statisticsAggregation.map(stat => {
                    if(stat.crawlCount === 0)
                        return {};
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "SetOrgs" + (this.canBeFiltered ? 'Filtered' : '') + "Min"]
                    };
                }),
            },
            {
                label: 'max(|organizations|)',
                borderWidth: 4,
                borderColor: 'transparent', // primary blue
                backgroundColor: 'rgba(25,151,198,0.1)',
                steppedLine: true,
                fill: '0',
                type: 'line',
                radius: 0, hitRadius: 0, hoverRadius: 0,
                data: statisticsAggregation.map(stat => {
                    if(stat.crawlCount === 0)
                        return {};
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "SetOrgs" + (this.canBeFiltered ? 'Filtered' : '') + "Max"]
                    };
                }),
            },
            {
                label: 'Nodes',
                fill: false,
                borderWidth: 2,
                steppedLine: true,
                type: 'line',
                radius: 2,
                borderColor: 'rgb(91, 128, 34)', // success green
                backgroundColor: 'rgb(91, 128, 34)', // success green
                data: statisticsAggregation.map(stat => {
                    if(stat.crawlCount === 0)
                        return {};
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "Set" + (this.canBeFiltered ? 'Filtered' : '') + "Average"]
                    };
                }),
            },
            {
                label: 'min(|nodes|)',
                fill: '3',
                borderWidth: 4,
                steppedLine: true,
                type: 'line',
                radius: 0, hitRadius: 0, hoverRadius: 0,
                borderColor: 'transparent', // success green
                backgroundColor: 'rgb(91, 128, 34)', // success green
                data: statisticsAggregation.map(stat => {
                    if(stat.crawlCount === 0)
                        return {};
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "Set" + (this.canBeFiltered ? 'Filtered' : '') + "Min"]
                    };
                }),
            },
            {
                label: 'max(|nodes|)',
                fill: '3',
                borderWidth: 4,
                steppedLine: true,
                type: 'line',
                radius: 0, hitRadius: 0, hoverRadius: 0,
                borderColor: 'transparent', // success green
                backgroundColor: 'rgb(91, 128, 34)', // success green
                data: statisticsAggregation.map(stat => {
                    if(stat.crawlCount === 0)
                        return {};
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "Set" + (this.canBeFiltered ? 'Filtered' : '') + "Max"]
                    };
                }),
            },
        ];
    }

    get day30ChartDataSets(): ChartDataSets[] {
        return this.getAggregatedDataSets(this.days30Statistics);
    }

    get yearChartDataSets(): ChartDataSets[] {
        return this.getAggregatedDataSets(this.yearStatistics);
    }

    get hour24ChartDataSets(): ChartDataSets[] {
        return [
            {
                label: 'Organizations',
                borderColor: '#2396cf', // primary blue
                backgroundColor: '#2396cf', // primary blue
                borderWidth: 2,
                steppedLine: true,
                fill: false,
                type: 'line',
                radius: 0, hitRadius: 5,
                data: this.hour24Statistics.map(stat => {
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "SetOrgs" + ((this.canBeFiltered ? 'Filtered' : '')) + "Size"]
                    };
                }),
            },
            {
                label: 'Nodes',
                borderColor: 'rgb(91 128 34)', // success green
                backgroundColor: 'rgb(91 128 34)', // success green
                borderWidth: 2,
                steppedLine: true,
                fill: false,
                type: 'line',
                radius: 0,hitRadius: 5,
                data: this.hour24Statistics.map(stat => {
                    return {
                        x: stat.time,
                        //@ts-ignore
                        y: stat["min" + this.capitalizeFirstLetter(this.setType) + "SetFilteredSize"]
                    };
                }),
            },
        ];
    }

    getAggregatedLabels(tooltipItem: ChartTooltipItem, data: ChartData) {
        //@ts-ignore
        let orgAvg = data.datasets![0]!.data![tooltipItem.index!]!.y;
        //@ts-ignore
        let minOrg = data.datasets![1]!.data![tooltipItem.index!]!.y;
        //@ts-ignore
        let maxOrg = data.datasets![2]!.data![tooltipItem.index!]!.y;
        //@ts-ignore
        let nodeAvg = data.datasets![3]!.data![tooltipItem.index!]!.y;
        //@ts-ignore
        let minNode = data.datasets![4]!.data![tooltipItem.index!]!.y;
        //@ts-ignore
        let maxNode = data.datasets![5]!.data![tooltipItem.index!]!.y;
        let nodesDescription = '';
        if (minNode === maxNode)
            nodesDescription = nodeAvg;
        else
            nodesDescription = `${minNode} to ${maxNode}`;

        let orgDescription = '';
        if (minOrg === maxOrg)
            orgDescription = orgAvg;
        else
            orgDescription = `${minOrg} to ${maxOrg}`;

        return `set(s) of ${nodesDescription} nodes across ${orgDescription} top tier organizations found that could impact ${this.analysisType}`;
    }

    getLabels(tooltipItem: ChartTooltipItem, data: ChartData) {
        //@ts-ignore
        let orgSize = data.datasets![0]!.data![tooltipItem.index!]!.y;
        //@ts-ignore
        let nodeSize = data.datasets![1]!.data![tooltipItem.index!]!.y;

        return `set(s) of ${nodeSize} nodes across ${orgSize} top tier organizations found that could impact ${this.analysisType}`;
    }

    async select30DayView(time?: Date) {
        if (time instanceof Date)
            this.selectedDate = time;
        await this.updateDays30Chart();
        this.bucketSize = '30D';
    }

    async select24HView(time?: Date) {
        if (time instanceof Date)
            this.selectedDate = time;

        await this.updateHours24Chart();
        this.bucketSize = '24H';
    }

    async updateSelectedDateAndHighlight(newDate: Date) {
        await this.updateSelectedDate(newDate);
        this.animated = true;
    }

    async updateYearChart() {
        this.isLoading = true;
        let oneYearAgo = moment(this.selectedDate).subtract(1, 'y').toDate();
        if(oneYearAgo < this.store.measurementsStartDate){
            oneYearAgo.setTime(this.store.measurementsStartDate.getTime());
            this.selectedDate = moment(this.store.measurementsStartDate).add(1, 'y').toDate();
        }

        try {
            this.failed = false;
            this.yearStatistics = await this.store.networkMeasurementStore.getMonthStatistics('stellar-public', oneYearAgo, this.selectedDate);
        } catch (e) {
            console.log(e);
            this.failed = true;
        }
        this.isLoading = false;
    }

    async updateDays30Chart() {
        this.isLoading = true;
        let startOfDay = moment(this.selectedDate).startOf('day').toDate();
        let days30Ahead = moment(this.selectedDate).startOf('day').add(30, 'd').toDate();
        try {
            this.failed = false;
            this.days30Statistics = await this.store.networkMeasurementStore.getDayStatistics('stellar-public', startOfDay, days30Ahead);
        } catch (e) {
            this.failed = true;
        }
        this.isLoading = false;
    }

    async updateHours24Chart() {
        this.isLoading = true;
        let startOfDay = moment(this.selectedDate).utc().startOf('day').toDate();
        let tomorrow = moment(this.selectedDate).utc().startOf('day').add(1, 'd').toDate();
        try {
            this.failed = false;
            let stats = await this.store.networkMeasurementStore.getStatistics('stellar-public', startOfDay, tomorrow);
            this.hour24Statistics = stats;
        } catch (e) {
            this.failed = true;
        }
        this.isLoading = false;
    }

    public async mounted() {
        this.statisticsDateTimeNavigator = new StatisticsDateTimeNavigator(this.store.measurementsStartDate);
        if(this.defaultBucketSize === '30D')
            await this.updateSelectedDate(moment(this.network.crawlDate).subtract(29, 'd').toDate());
        else
            await this.updateSelectedDate(this.network.crawlDate);

        this.initialDataLoaded = true;
    }

    protected capitalizeFirstLetter(myString:string) {
        return myString.charAt(0).toUpperCase() + myString.slice(1);
    }
}
