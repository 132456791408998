






























import {Component, Mixins} from 'vue-property-decorator';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {},
    directives: {}
})
export default class LivenessInfo extends Mixins(StoreMixin) {
}
