




























import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Store from '@/store/Store';
import moment from 'moment';
import {
    BButton,
    BIconChevronRight,
    BIconChevronLeft,
    BIconClock,
    BFormDatepicker,
    BFormTimepicker,
    VBTooltip,
    BIconCalendar,
    BButtonGroup
} from 'bootstrap-vue';
import StatisticsDateTimeNavigator
    from '@/components/network/cards/network-risk-analysis-charts/StatisticsDateTimeNavigator';

@Component({
    name: 'date-navigator',
    components: {
        BButton,
        BIconChevronRight,
        BIconClock,
        BFormDatepicker,
        BFormTimepicker,
        BIconChevronLeft,
        BIconCalendar,
        BButtonGroup
    },
    directives: {'b-tooltip': VBTooltip}
})
export default class DateNavigator extends Vue {
    @Prop()
    selectedDate!: Date;
    @Prop()
    bucketSize!: string;
    @Prop({default: false})
    showTime!: boolean;

    protected statisticsDateTimeNavigator!: StatisticsDateTimeNavigator;

    datePickerDate: Date = this.selectedDate;
    time: string = moment(this.selectedDate).format('HH:mm');
    timeKey: string = this.time; //needed for animation trigger

    canGoBack() {
        return this.statisticsDateTimeNavigator.canGoBack(this.bucketSize, this.datePickerDate);
    }

    goBack() {
        this.$nextTick(() => {
            this.datePickerDate = this.statisticsDateTimeNavigator.goBack(this.bucketSize, this.datePickerDate);
            this.time = moment(this.datePickerDate).format('HH:mm');
            this.timeKey = this.time;
            this.$emit('dateChanged', this.datePickerDate);
        });
    }

    goForward() {
        this.$nextTick(() => {
            this.datePickerDate = this.statisticsDateTimeNavigator.goForward(this.bucketSize, this.datePickerDate);
            this.time = moment(this.datePickerDate).format('HH:mm');
            this.timeKey = this.time;
            this.$emit('dateChanged', this.datePickerDate);
        });

    }

    @Watch('selectedDate', {deep: true})
    async onSelectedDateChanged(to: string, from: string) {
        this.datePickerDate = this.selectedDate;
        this.time = moment(this.selectedDate).format('HH:mm');
        this.timeKey = this.time;
    }

    @Watch('datePickerDate', {})
    async onDatePickerDateChanged(to: string, from: string) {
        if (this.datePickerDate && from !== null && this.selectedDate !== this.datePickerDate) {
            this.time = moment(this.datePickerDate).format('HH:mm');
            this.timeKey = this.time;
            this.$emit('dateChanged', new Date(this.datePickerDate));
        }
    }

    timeInputHandler() {
        if (this.time !== moment(this.datePickerDate).startOf('minutes').format('HH:mm:ss')) {
            this.datePickerDate = moment(this.datePickerDate).hours(Number(this.time.substr(0, 2))).minutes(Number(this.time.substr(3, 2))).toDate();
            this.time = moment(this.datePickerDate).format('HH:mm');
            this.timeKey = this.time;
            this.$emit('dateChanged', this.datePickerDate);
        }
    }

    get store(): Store {
        return this.$root.$data.store;
    }

    timeTravel() {
        let query = this.store.copyAndModifyObject(this.$route.query, [{key: 'at', value: this.datePickerDate.toISOString()}])
        this.$router.push(
            {
                name: this.$route.name ? this.$route.name : undefined,
                params: this.$route.params,
                query: query
            },
        );

    }

    public async created() {
        this.statisticsDateTimeNavigator = new StatisticsDateTimeNavigator(this.store.measurementsStartDate);
    }
}
