


















import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {Network, Node, Organization, QuorumSet} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';
import NodesTable from '@/components/node/nodes-table.vue';
import {BBadge, BIconSearch} from 'bootstrap-vue';

@Component({
    components: {NodesTable, BIconSearch: BIconSearch, BBadge:BBadge}
})
export default class NetworkNodes extends Vue{
    @Prop()
    node!: Node;

    protected filter:string="";

    get fields(): any {
        let fields = [
            {key: 'name', label: 'Node', sortable: true}
        ]
        if(!this.store.isSimulation){
            fields.push(
                {key: 'index', label: 'Index', sortable: true}
            );
        }

        //@ts-ignore
        fields.push({key: 'action', label: '', sortable: false, tdClass: 'action'});

        return fields;
    }

    get store(): Store {
        return this.$root.$data.store;
    }

    get network(): Network {
        return this.store.network;
    }

    get numberOfActiveNodes() {
        if(this.store.includeWatcherNodes)
            return this.network.nodes.filter((node) => !this.network.isNodeFailing(node)).length;
        else
            return this.network.nodes.filter((node) => node.isValidator && !this.network.isNodeFailing(node)).length;
    }

    get validators() {
        return this.network.nodes.filter(node => node.isValidator || this.store.includeWatcherNodes)
            .map(node => {
                return {
                    isFullValidator: node.isFullValidator,
                    name: node.displayName,
                    index: node.index,
                    publicKey: node.publicKey
                }
            })
    }
}
