














import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {Network, Organization} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';
import {BListGroup, BListGroupItem} from 'bootstrap-vue';

@Component({
    components: {BListGroup, BListGroupItem}
})
export default class NetworkHorizon extends Vue {

    get store(): Store {
        return this.$root.$data.store;
    }

    get network(): Network {
        return this.store.network;
    }

    get horizons() {
        return this.network.organizations.filter(organization => organization.horizonUrl !== undefined).map(organization => {
            return {
                name: organization.name,
                url: organization.horizonUrl
            };
        });
    }

}
