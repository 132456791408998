

















































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {Network, Node} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';

import {BBadge, BIconShield, BPagination, BTable, VBTooltip} from 'bootstrap-vue';
import NodeActions from '@/components/node/sidebar/node-actions.vue';

@Component(
    {
        components: {NodeActions, BTable, BPagination, BIconShield: BIconShield, BBadge: BBadge},
        directives: {'b-tooltip': VBTooltip}
    })
export default class NodesTable extends Vue {
    @Prop({default: ''})
    public filter!: string;
    @Prop()
    public fields!: any;
    @Prop()
    public nodes!: Node[];
    @Prop({default: 200})
    public perPage!: number;

    public sortBy: string = 'index';
    public sortDesc: boolean = true;

    public currentPage: number = 1;

    get store(): Store {
        return this.$root.$data.store;
    }

    get network() {
        return this.store.network;
    }

    get totalRows(): number {
        return this.nodes.length;
    }
}
