
































import {Component, Mixins} from 'vue-property-decorator';
import {BBadge, BIconSearch, BTable, BIconExclamationTriangle, BListGroup, BListGroupItem} from 'bootstrap-vue';
import {StoreMixin} from '@/mixins/StoreMixin';
//import AsyncComputed from 'vue-async-computed-decorator';
import {IsLoadingMixin} from '@/mixins/IsLoadingMixin';
import {OrganizationSnapShot} from '@stellarbeat/js-stellar-domain';

@Component({
    components: {
        BIconSearch: BIconSearch,
        BBadge: BBadge,
        BTable,
        BIconExclamationTriangle,
        BListGroup,
        BListGroupItem
    }
})
export default class NetworkValidatorUpdates extends Mixins(StoreMixin, IsLoadingMixin) {
    failed: boolean = false;
    snapshots:OrganizationSnapShot[] = [];

    async getSnapshots() {
        let snapshots: OrganizationSnapShot[] = [];
        try {
            snapshots = await this.store.fetchOrganizationSnapshots();
        } catch (e) {
            console.log(e);
            this.failed = true;
        }
        this.isLoading = false;
        return snapshots;
    }

    async mounted(){
        this.snapshots = await this.getSnapshots();
    }
}
